import _initializerDefineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerDefineProperty.js";
import _defineProperty from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _applyDecoratedDescriptor from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/applyDecoratedDescriptor.js";
import _initializerWarningHelper from "/var/www/discourse/node_modules/@babel/runtime/helpers/esm/initializerWarningHelper.js";
var _class, _descriptor, _descriptor2, _descriptor3, _HomeLogo;
import Component from "@glimmer/component";
import { hash } from "@ember/helper";
import { on } from "@ember/modifier";
import { action } from "@ember/object";
import { service } from "@ember/service";
import PluginOutlet from "discourse/components/plugin-outlet";
import concatClass from "discourse/helpers/concat-class";
import { wantsNewWindow } from "discourse/lib/intercept-click";
import DiscourseURL from "discourse/lib/url";
import icon from "discourse-common/helpers/d-icon";
import getURL from "discourse-common/lib/get-url";
import Logo from "./logo";
import { setComponentTemplate } from "@ember/component";
import { createTemplateFactory } from "@ember/template-factory";
let HomeLogo = (_class = (_HomeLogo = class HomeLogo extends Component {
  constructor() {
    super(...arguments);
    _initializerDefineProperty(this, "session", _descriptor, this);
    _initializerDefineProperty(this, "site", _descriptor2, this);
    _initializerDefineProperty(this, "siteSettings", _descriptor3, this);
    _defineProperty(this, "href", getURL("/"));
    _defineProperty(this, "darkModeAvailable", this.session.darkModeAvailable);
  }
  get showMobileLogo() {
    return this.site.mobileView && this.logoResolver("mobile_logo").length > 0;
  }
  get logoUrl() {
    return this.logoResolver("logo");
  }
  get logoUrlDark() {
    return this.logoResolver("logo", {
      dark: this.darkModeAvailable
    });
  }
  get logoSmallUrl() {
    return this.logoResolver("logo_small");
  }
  get logoSmallUrlDark() {
    return this.logoResolver("logo_small", {
      dark: this.darkModeAvailable
    });
  }
  get mobileLogoUrl() {
    return this.logoResolver("mobile_logo");
  }
  get mobileLogoUrlDark() {
    return this.logoResolver("mobile_logo", {
      dark: this.darkModeAvailable
    });
  }
  logoResolver(name1) {
    let opts1 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    // get alternative logos for browser dark dark mode switching
    if (opts1.dark) {
      return this.siteSettings[`site_${name1}_dark_url`];
    }
    // try dark logos first when color scheme is dark
    // this is independent of browser dark mode
    // hence the fallback to normal logos
    if (this.session.defaultColorSchemeIsDark) {
      return this.siteSettings[`site_${name1}_dark_url`] || this.siteSettings[`site_${name1}_url`] || "";
    }
    return this.siteSettings[`site_${name1}_url`] || "";
  }
  click(e1) {
    if (wantsNewWindow(e1)) {
      return false;
    }
    e1.preventDefault();
    DiscourseURL.routeToTag(e1.target.closest("a"));
  }
}, setComponentTemplate(createTemplateFactory(
/*
  
    <PluginOutlet @name="home-logo" @outletArgs={{hash minimized=@minimized}}>
      <div class={{concatClass (if @minimized "title--minimized") "title"}}>
        <a href={{this.href}} {{on "click" this.click}}>
          <PluginOutlet
            @name="home-logo-contents"
            @outletArgs={{hash
              minimized=@minimized
              logoUrl=this.logoUrl
              logoSmallUrl=this.logoSmallUrl
              showMobileLogo=this.showMobileLogo
            }}
          >
            {{#if @minimized}}
              {{#if this.logoSmallUrl}}
                <Logo
                  @key="logo-small"
                  @url={{this.logoSmallUrl}}
                  @title={{this.siteSettings.title}}
                  @darkUrl={{this.logoSmallUrlDark}}
                />
              {{else}}
                {{icon "home"}}
              {{/if}}
            {{else if this.showMobileLogo}}
              <Logo
                @key="logo-mobile"
                @url={{this.mobileLogoUrl}}
                @title={{this.siteSettings.title}}
                @darkUrl={{this.mobileLogoUrlDark}}
              />
            {{else if this.logoUrl}}
              <Logo
                @key="logo-big"
                @url={{this.logoUrl}}
                @title={{this.siteSettings.title}}
                @darkUrl={{this.logoUrlDark}}
              />
            {{else}}
              <h1 id="site-text-logo" class="text-logo">
                {{this.siteSettings.title}}
              </h1>
            {{/if}}
          </PluginOutlet>
        </a>
      </div>
    </PluginOutlet>
  
*/
{
  "id": "ta7LTSsj",
  "block": "[[[1,\"\\n    \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"home-logo\",[28,[32,1],null,[[\"minimized\"],[[30,1]]]]]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[15,0,[28,[32,2],[[52,[30,1],\"title--minimized\"],\"title\"],null]],[12],[1,\"\\n        \"],[11,3],[16,6,[30,0,[\"href\"]]],[4,[32,3],[\"click\",[30,0,[\"click\"]]],null],[12],[1,\"\\n          \"],[8,[32,0],null,[[\"@name\",\"@outletArgs\"],[\"home-logo-contents\",[28,[32,1],null,[[\"minimized\",\"logoUrl\",\"logoSmallUrl\",\"showMobileLogo\"],[[30,1],[30,0,[\"logoUrl\"]],[30,0,[\"logoSmallUrl\"]],[30,0,[\"showMobileLogo\"]]]]]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,1],[[[41,[30,0,[\"logoSmallUrl\"]],[[[1,\"                \"],[8,[32,4],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-small\",[30,0,[\"logoSmallUrl\"]],[30,0,[\"siteSettings\",\"title\"]],[30,0,[\"logoSmallUrlDark\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[28,[32,5],[\"home\"],null]],[1,\"\\n\"]],[]]]],[]],[[[41,[30,0,[\"showMobileLogo\"]],[[[1,\"              \"],[8,[32,4],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-mobile\",[30,0,[\"mobileLogoUrl\"]],[30,0,[\"siteSettings\",\"title\"]],[30,0,[\"mobileLogoUrlDark\"]]]],null],[1,\"\\n\"]],[]],[[[41,[30,0,[\"logoUrl\"]],[[[1,\"              \"],[8,[32,4],null,[[\"@key\",\"@url\",\"@title\",\"@darkUrl\"],[\"logo-big\",[30,0,[\"logoUrl\"]],[30,0,[\"siteSettings\",\"title\"]],[30,0,[\"logoUrlDark\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"              \"],[10,\"h1\"],[14,1,\"site-text-logo\"],[14,0,\"text-logo\"],[12],[1,\"\\n                \"],[1,[30,0,[\"siteSettings\",\"title\"]]],[1,\"\\n              \"],[13],[1,\"\\n            \"]],[]]]],[]]]],[]]],[1,\"          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"]],[\"@minimized\"],false,[\"if\"]]",
  "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/node_modules/.embroider/rewritten-app/components/header/home-logo.js",
  "scope": () => [PluginOutlet, hash, concatClass, on, Logo, icon],
  "isStrictMode": true
}), _HomeLogo), _HomeLogo), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "site", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _applyDecoratedDescriptor(_class.prototype, "click", [action], Object.getOwnPropertyDescriptor(_class.prototype, "click"), _class.prototype)), _class);
export { HomeLogo as default };